/**
 * Const with params element
 * Render Release Notes component
 * Render the number of release notes
 */
/* Import section */
import React from "react"
import Cardreleasenotes from "../components/cardreleasenotes"
import Titledecorative from "../components/titledecorative"

/* Declaration function */
const Renderreleasenotes = ({ postsToRender }) => {

  let arrDateRelease = []
  postsToRender.map(({ node }) => (
    arrDateRelease.push(node.date)
  ))

  const arrCountValueDateRelease = getCountIdenticValue(arrDateRelease)
  const arrDatesValue= arrCountValueDateRelease[0]

  return (
    <div className="wrap-maincontent">
      {arrDatesValue.map( (date) => (
        <div className="wrap-loop-releases">
          <Titledecorative title={date} />
          <div className="loop-card-release">
            {postsToRender.filter(post => post.node.date === date).map(({ node }) => (
              <Cardreleasenotes
                titleRelease={node.title}
                slugRelease={node.slug}
                contentRelease={node.content !== null ? node.content : node.acfPostRelease}
                classRelease={node.content !== null ? "text" : "repeater"}
              />
            ))}
          </div>
        </div>
      ))}

    </div>
  )
}

/* Export function */
export default Renderreleasenotes

// Function to get identic value in array
function getCountIdenticValue(arr) {
  var a = [], b = [], prev;

  for ( var i = 0; i < arr.length; i++ ) {
    if ( arr[i] !== prev ) {
      a.push(arr[i]);
      b.push(1);
    } else {
      b[b.length-1]++;
    }
    prev = arr[i];
  }

  return [a, b];
}