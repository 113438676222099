/**
 * Const with params element
 * Card release notes component
 * Display a card of summary release notes
 */
/* Import section */
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import TruncateText from "../components/truncatetext"
import "../styles/components/cardreleasenotes.scss"

/* Declaration function */
const Cardreleasenotes = ({ titleRelease, slugRelease, contentRelease, classRelease }) => {
  //Types de content
  var typeContent = ""
  if (contentRelease.repeaterLevel1 !== undefined) {
    typeContent = "repeater"
    let arrTypesRelease = []
    let arrUniqueTypesRelease = []
    var repeaterLevels = contentRelease.repeaterLevel1
    repeaterLevels.map( (level1 )  => (
      arrTypesRelease.push(level1.typeLevel1[0])
    ))
    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }
    arrUniqueTypesRelease = arrTypesRelease.filter(unique)
    var arrLevelofTypeFeatures = []
    var arrLevelofTypeComingsoon = []
    arrUniqueTypesRelease.filter(item => item === "features").map( (type) => (
      arrLevelofTypeFeatures = repeaterLevels.filter(function(element) {
        return element.typeLevel1[0] === type
      })
    ))
    arrUniqueTypesRelease.filter(item => item === "comingsoon").map( (type) => (
      arrLevelofTypeComingsoon = repeaterLevels.filter(function(element) {
        return element.typeLevel1[0] === type
      })
    ))
  }else {
    typeContent = "text"
  }

  return (
    <div className={`card-release-notes card-clickable ${classRelease}`}>
      <h3>{titleRelease}</h3>
      {typeContent === "repeater" ? [
        <div className="wrap-content-repeater-release">
          <p className="type-release"><span>Feature Updates</span></p>
          {arrLevelofTypeFeatures.map( (item1, index1 )  => (
            <div className="repeater-release-level-1">
              <h4>{item1.titleLevel1}</h4>
              {item1.repeaterLevel2 !== null && (
                <ul className="repeater-release-level-2">
                  {item1.repeaterLevel2.map( (item2, index2) =>
                    <li id={`release-${index1}-${index2}`}>{item2.titleLevel2}</li>
                  )}
                </ul>
              )}
            </div>
          ))}
          {arrLevelofTypeComingsoon.length > 0 && [
            <div>
              <p className="type-release"><span className="comingsoon">Coming Soon</span></p>
              {arrLevelofTypeComingsoon.map( (item1, index1 )  => (
                <div className="repeater-release-level-1">
                <h4>{item1.titleLevel1}</h4>
                {item1.repeaterLevel2 !== null && (
                  <ul className="repeater-release-level-2">
                    {item1.repeaterLevel2.map( (item2, index2) =>
                      <li id={`release-${index1}-${index2}`}>{item2.titleLevel2}</li>
                    )}
                  </ul>
                )}
              </div>
              ))}
            </div>
          ]}
        </div>
      ] : (
        <div className="wrap-content-text-release">
          <p><TruncateText str={contentRelease} length="200" ending="..." /></p>
        </div>
      )}

      <div className={`box-ctatext-card ${classRelease}`}>
        <Ctabuttonwitharrow ctaLabel="Read More" ctaLink={`/product-updates/${slugRelease}`} />
      </div>
      <a href={`/product-updates/${slugRelease}/`} aria-label="Read More"><span className="span-cta-card" >Read More</span></a>
    </div>
  )
}

/* Export function */
export default Cardreleasenotes

