/**
 * Post list Template (blog or news)
 */
/* Import section */
import { graphql } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Cardreleasenotes from "../components/cardreleasenotes"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Renderreleasenotes from "../components/renderreleasenotes"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/templates/releaselist.scss"

const postsPerPage = 2
let arrayForHoldingPosts = []

/* Declaration class */
const ReleaseListTemplate = ({ data }) => {

  //Releases Posts infos
  const dataPage = data.wpPage
  const dataReleases = data.allWpRelease
  const dataReleasesHero = data.heroRelease.edges
  const h1Post = dataPage.acfPageProductupdates.titleHeroProductUpdates
  const catDesc = dataPage.acfPageProductupdates.textHeroProductUpdates
  const namePost = dataPage.title

  //Load More functionnality
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(2)
  const [slugPost, setSlugPost] = useState(0)
  const prevSlugPostRef = useRef()
  const prevSlugPost = prevSlugPostRef.current
  const lengthPostsToShow = postsToShow.length
  const postsPosts = dataReleases.edges
  const lenghtPostsPosts = postsPosts.length
  var showLoadMore = (lenghtPostsPosts > postsPerPage && lenghtPostsPosts !== lengthPostsToShow) ? true : false

  if(!showLoadMore || prevSlugPost === undefined) {
    arrayForHoldingPosts = []
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = postsPosts.slice(start, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    prevSlugPostRef.current = slugPost
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
    setSlugPost(dataReleases.slug)
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "support2"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      <section className="section-hero-release-list top-section">
        <Herotype2 h1title={h1Post} textIntro={catDesc} colorBar="white" />
      </section>

      <section className="section-hero-releases">
        <div className="hero-loop-releases wrap-maincontent">
          <Titledecorative title={dataReleasesHero[0].node.date} />
          {dataReleasesHero.map( (herorelease, index) => (
            <Cardreleasenotes key={index} classRelease="hero" titleRelease={herorelease.node.title} slugRelease={herorelease.node.slug} contentRelease={(herorelease.node.content !== null) ? herorelease.node.content : herorelease.node.acfPostRelease} />
          ))}
        </div>
      </section>

      {/* Section with loop on all Posts */}
      <section className="section-loop-releases">
        <Renderreleasenotes postsToRender={postsToShow} />
        {showLoadMore &&
        <div className="box-loadmore">
          <button onClick={handleShowMorePosts}>Load More {namePost}</button>
        </div>
        }
      </section>

      {/* Banner CTA */}
      <Bannerwithcta
        classBanner="section-banner-releases"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default ReleaseListTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($slug: String!) {
    wpPage(slug: {eq: $slug}) {
      databaseId
      title
      slug
      acfPageProductupdates {
        textHeroProductUpdates
        titleHeroProductUpdates
      }
      seo {
        title
        metaDesc
      }
    }
    allWpRelease(sort: {fields: [date], order:DESC}, skip: 1) {
      edges {
        node {
          title
          slug
          content
          date (
            formatString: "MMMM YYYY"
          )
          acfPostRelease {
            repeaterLevel1 {
              contentLevel1
              titleLevel1
              typeLevel1
              repeaterLevel2 {
                contentLevel2
                titleLevel2
              }
            }
          }
        }
      }
    }
    heroRelease: allWpRelease(sort: {fields: [date], order:DESC}, limit: 1) {
      edges {
        node {
          title
          slug
          content
          date (
            formatString: "MMMM YYYY"
          )
          acfPostRelease {
            repeaterLevel1 {
              contentLevel1
              titleLevel1
              typeLevel1
              repeaterLevel2 {
                contentLevel2
                titleLevel2
              }
            }
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
